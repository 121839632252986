import React, { useState } from 'react';
import SingleHistory from './single-history-order';
import './history.css';
import OrderData from '../orders/order-data';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Todaysdate from '../../components/date-time/Todays-date'
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

function History({translation, language}) {
    const dictionary = getDictionary(translation, 'history');
    const dataComp = OrderData.map((data)=> {
return(

         <SingleHistory
            number={data.number}
            address={data.address}
            delivery={data.delivery}
            price={data.price}
            />  
        )})

    const [isSelected, setIsSelected] = useState('cash');
    const handleToggle = (buttonType) => {
        if (isSelected !== buttonType) {
          setIsSelected(buttonType);
        }};

    const subtotal = OrderData.reduce((acc, data) => {
        return acc + data.price ;
      }, 0);
    
      const deliveryCharge = 18; 
      const tip = 32; 
      const total = subtotal + deliveryCharge + tip;
    
        return (
                 
    <>
        <div className="history-fixed">
    <div  className="history-buttons">

<div onClick={() => handleToggle('cash')}
className={`cash-button ${isSelected === 'cash' ? 'active' : ''}`} 
style={{borderBottom: isSelected === 'cash' ? 'solid' : 'none',
        borderColor: isSelected === 'cash' ? '#1DB4B8' : '',
        fontWeight: isSelected === 'cash' ? 'bold' : 'normal',
       }}>Cash</div>

        <div  onClick={() => handleToggle('creditCard')}
  className={`card-button ${isSelected === 'creditCard' ? 'active' : ''}`}
        style={{
            borderBottom: isSelected === 'creditCard' ? 'solid' : 'none',
            borderColor: isSelected === 'creditCard' ? '#1DB4B8' : '',
            fontWeight: isSelected === 'creditCard' ? 'bold' : 'normal',
            }}>Credit Card</div>

        <div  onClick={() => handleToggle('online')}
  className={`online-button ${isSelected === 'online' ? 'active' : ''}`} 
        style={{
            borderBottom: isSelected === 'online' ? 'solid' : 'none',
            borderColor: isSelected === 'online' ? '#1DB4B8' : '',
            fontWeight: isSelected === 'online' ? 'bold' : 'normal',
            }}>Online</div>
        
            </div>

        <div className="date-history">
            <CalendarMonthIcon className='date-icon' sx={{fontSize:'20px'}}/>
            <Todaysdate/>
        </div>
        </div>
       
       <div className='history'>
         {dataComp} 

    <div className="history-box">
<div className='history-total-container'>    
<div className='history-subtotal'>        <div>{getField(dictionary, 'subtotal', language)} </div> <div>{subtotal} USD</div>                    </div>
<div className='history-delivery-charge'> <div>{getField(dictionary, 'delivery-charge', language)}</div> <div>{deliveryCharge} USD</div>        </div>  
<div className='history-tip'>        <div>{getField(dictionary, 'tip', language)}</div> <div>{tip} USD</div> </div>  
<div className='history-total'>           <b>{getField(dictionary, 'total', language)}</b> <b>{total} USD</b>                        </div>
</div> 
<div className='history-closing-report'>
<Link to="/history/closing-report" className='closing-report-button'>
<Button sx={{ backgroundColor: '#1DB4B8', color:'white', width:'18rem',height:'3.4rem',fontWeight:'bold',fontFamily:'Segoe UI',borderRadius:'18px',
fontSize:'1.3rem',  marginBottom:'1rem', textTransform:'none', '&:hover': {backgroundColor: '#1DB4B8',} }}>{getField(dictionary, 'closing-report-button', language)}</Button> </Link>

</div>
</div>
</div>
</>
 ); }

 const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(History);