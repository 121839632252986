import React, {useState} from 'react';
import './location.css';
import SmallMap from './SmallMap';
import Direction from '../../../direction/direction';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../../utils/translation";

function Location({translation, language}) {

    const dictionary = getDictionary(translation, 'details');

    const [showUp, setShowUp] = useState(false)
    const openDirection = () => {
        setShowUp(true);
    };

    const closeDirection = () => {
        setShowUp(false);
    };

    
    return  (
        <>
        <div className='location-container'>
<div className='loc-di'>
    <div className='location-title'> {getField(dictionary, 'location-title', language)} </div>
  
        <h6 onClick={openDirection} className="get-direction-button">{getField(dictionary, 'apartment', language)}</h6>
   

</div>
    <div className='location-map' >
    <div className="small-map-div" onClick={openDirection}>
       <SmallMap className='small-map' />
       </div>  
       
    <div className='order-info'>
                <div className='customer-location-title'>{getField(dictionary, 'order-status-title', language)}<div className='order-status'>Done</div></div>
                <div className='customer-location-title'>{getField(dictionary, 'pick-up-time-title', language)}<div className='pickup-time'>12:30PM</div></div>
                <div className='customer-location-title'>{getField(dictionary, 'delivery-time-title', language)}<div className='delivery-time'>12:55PM</div></div>
                </div>
                </div>

    </div>

<div className='direction-popup' > <Direction open={showUp} onClose={closeDirection}/> </div> 
</>
    );
  }
  
  const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(Location);
  