import React from 'react';
import { TextField, Button, Container, InputAdornment } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

import '../signup/signup.css'

function Signup({translation, language}) {
    const dictionary = getDictionary(translation, 'signup');
  function handleSubmit(e)  {
    e.preventDefault()

  }

  return (
<body className='body-signup'>
  <Container className='container-signup' maxWidth="sm">
    <form className='signup-form' onSubmit={handleSubmit}>
      
      <div className='signup-head'>
        <div className='signup-title'>{getField(dictionary, 'signup-title', language)}</div>
        <div className='signup-subtext'>{getField(dictionary, 'signup-title-subtext', language)}</div>
      </div>

      <div className='signup-inputs'>
          <TextField
            className='email-field'
            placeholder={getField(dictionary, 'email-input', language)}
            type="email"
            variant="filled"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon className='email-icon'/>
                </InputAdornment>  ),}} />

          <TextField
            placeholder={getField(dictionary, 'password-input ', language)}
            type="password"
            variant="filled"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon className='password-icon'/>
                </InputAdornment>  ),}} />

                <TextField
            placeholder={getField(dictionary, 're-enter-password-input', language)}
            type="password"
            variant="filled"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon className='password-icon' />
                </InputAdornment>  ),}} />

      </div>
      <div className='signup-button'>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{backgroundColor:'white',
            color:'#1DB4B8',
            height:'3.5rem',
            fontSize:'20px',
            borderRadius:'15px',
            textTransform:'none',
            '&:hover': {backgroundColor: '#F2F2F2'}}}
          > {getField(dictionary, 'signup-button', language)} </Button>
        </div>

        <Link to="/login" className='go-login-page'>
        {getField(dictionary, 'have-account-button', language)}
        </Link> 
          
      </form>
   
    </Container>
    </body>
  );
};


const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(Signup);