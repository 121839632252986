import React  from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import "./single-order.css";
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";


function SingleOrder({number, distance, address, status, pickup, delivery, translation, language}) {

const handleClick = () => {
window.location.href = "/orders/details";
};

const dictionary = getDictionary(translation, 'orders');

return (

<button className='order-details-button' onClick={handleClick}> 

  <Box className='order-box'>

    <div className='order-container'>
      <div className='order-location-logo'><LocationOnIcon style={{color:'white', backgroundColor:'#1DB4B8', borderRadius:'50%', padding:'0.3rem'}}/></div>
      <div className='order-title'>{getField(dictionary, 'order-title', language)}</div>
      <div className='order-tag-nbr'>
        <div className='order-tag'>#</div>
        <div className='order-nbr'>{number}</div>
      </div>

      <div to="/details" className='order-details'>{getField(dictionary, 'details title', language)}</div>
      <div className='distance'>{distance}</div> 
      <div className='location'>{address}</div> 

      <div className='order-status-title'>
      {getField(dictionary, 'order-status-title', language)}
        <div className='order-status' >{status}</div>
      </div>

      <div className='pickup-time-title'>
      {getField(dictionary, 'pick-up-time-title', language)}
        <div className='pickup-time'>{pickup}</div>
      </div>

      <div className='delivery-time-title'>
      {getField(dictionary, 'delivery-time-title', language)}
        <div className='delivery-time'>{delivery}</div>
      </div>

    </div>

  </Box>

</button>

);
}
const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder);
 