import React from 'react';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
export default function MapDirection () {

const { isLoaded } = useLoadScript({
googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
});
const center =useMemo(()=>({lat:33.972875, lng: 35.610006}) ,[]);

if (!isLoaded) return <div>Loading...</div>;

return (
    
<GoogleMap 
zoom={18} 
center={center} 
mapContainerClassName='map-direction' 
style={{height:"100%", margin:"0", padding:"0"}}> 
<Marker position={center} />
</GoogleMap>
    
);
}