const OrderData = [ 
{number: '1001',
distance: '2km',
address: 'Zone A-Street abc, Build,xyz, floor 3, 307.',
status: 'Done',
pickup: '12:30PM',
delivery: '12:55PM',
price:84,
payment: 'CASH',
},

{number: '1002',
    distance: '5km',
    address: 'Zone B-Street def, Build,uvw, floor 4, 526.',
    status: 'In process',
    pickup: '11:47PM',
    delivery: '12:20PM',
    price:84,
    payment: 'CREDIT CARD',
},

{number: '1003',
        distance: '7km',
        address: 'Zone C-Street ghi, Build,xyz, floor 3, 307.',
        status: 'Done',
        pickup: '2:05PM',
        delivery: '2:15PM',
        price:84,
        payment: 'ONLINE',
},

{number: '1004',
        distance: '10km',
        address: 'Zone C-Street ghi, Build,xyz, floor 3, 307.',
        status: 'In process',
        pickup: '2:20PM',
        delivery: '2:30PM',
        price:84,
        payment: 'CASH',
},


{number: '1005',
        distance: '6km',
        address: 'Zone S-Street java, Build,python, floor 3.14, 159.',
        status: 'Done',
        pickup: '1:30PM',
        delivery: '1:45PM',
        price:84,
        payment: 'CREDIT CARD',
},

{number: '1006',
        distance: '1km',
        address: 'Presidence Street, Fares Saadeh Building, floor 1,4,5,6.',
        status: 'In process',
        pickup: '5:20PM',
        delivery: '5:30PM',
        price:84,
        payment: 'ONLINE',
},

{number: '1007',
        distance: '4km',
        address: 'Zone C-Street ghi, Build,xyz, floor 3, 307.',
        status: 'Done',
        pickup: '12:30PM',
        delivery: '12:55PM',
        price:84,
        payment: 'CASH',
},

];

export default OrderData;










