import React from 'react'
import SingleClosingReport from './single-closing-report';
import OrderData from '../../../pages/orders/order-data'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './closing-report.css'
import DateNow from '../../../components/date-time/date-now'
import Clock from '../../../components/date-time/clock'
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import SummaryPayment from '../ClosingReport/summary-payment';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";

function ClosingReport({translation, language}) {
    const dictionary = getDictionary(translation, 'history');

    const dataComp = OrderData.map((data)=> {

        return(

         <SingleClosingReport
            number={data.number}
            payment={data.payment}
            delivery={data.delivery}
            price={data.price}
            />  
        )
    })
        return (
                 
            <>
              
<div className='closing-report'>

<div className="cr-fixed">
<div className='cr-head'>
<Link to="/history" className='back-to-history'>  <IconButton > <ArrowBack sx={{color:'black', fontSize:'2rem'}} /> </IconButton> </Link>
          <div className='cr-title'> {getField(dictionary, 'closing-report-title', language)} </div>

          
        <div className='cr-date-time'>
                <div className='cr-date'> <div className='cr-dt-title'> {getField(dictionary, 'date-title', language)}</div> <DateNow /> </div>
                <div className='cr-time'> <div className='cr-dt-title'>{getField(dictionary, 'time-title', language)} </div> <Clock /> </div>
        </div>     
</div>
                <SummaryPayment/>
</div>
            <div className='closing-report-orders'>
                
         {dataComp} 
         </div>

    <Link to="/closing-report" className='stop-work-button'>
<Button sx={{
        backgroundColor: '#CF0000', 
        color:'white',
        width:'350px',
        height:'60px',
        fontWeight:'bold',
        fontFamily:'Segoe UI',
        borderRadius:'20px',
        fontSize:'1.5rem',
        textTransform:'none',
        
        '&:hover': {backgroundColor: '#B70000',} }}>{getField(dictionary, 'stop-button', language)}</Button> </Link> 
      
      </div>
            </>
        );
    }
    const mapStateToProps = (state) => ({
        translation: state.translation.translation,
        language: state.translation.language,
       });
       
       const mapDispatchToProps = (dispatch) => ({
          
        });
       
        export default connect(mapStateToProps, mapDispatchToProps)(ClosingReport);