import React from 'react'

export default function Todaysdate() {
    const date = new Date();
    const day = date.toLocaleDateString('en-US', { weekday: 'long' });
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    const dayOfMonth = date.toLocaleDateString('en-US', { day: 'numeric' });
    const year = date.getFullYear();
    const formattedDate = `${day} ${dayOfMonth}, ${month} ${year}`;

    return <div className='full-date'>{formattedDate}</div>;
  }