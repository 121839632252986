import React from 'react';
import './items.css';
import SingleItem from './single-item';
import ItemsData from './items-data';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../../utils/translation";

function OrderItems ({translation, language}) {
    const dictionary = getDictionary(translation, 'details');
    const dataItems = ItemsData.map((data)=> {
        return(
            
            <SingleItem
            quantity={data.quantity}
            name={data.name}
            price={data.price*data.quantity}
            />
        )
    })

    const subtotal = ItemsData.reduce((acc, data) => {
        return acc + data.price*data.quantity ;
      }, 0);
    
      const deliveryCharge = 3; 
      const discount = 9; 
    
      const total = subtotal + deliveryCharge - discount;

return(
<>
<Box>
<div className='items-title'>{getField(dictionary, 'items-title', language)}</div>
<div className='data-items'>{dataItems}</div>
<div className='items-total-container'>    
<div className='items-subtotal'>        <div>{getField(dictionary, 'subtotal', language)} </div> <div>{subtotal} USD</div>                    </div>
<div className='items-delivery-charge'> <div>{getField(dictionary, 'delivery-charge', language)}</div> <div>{deliveryCharge} USD</div>        </div>  
<div className='items-discount'>        <div>{getField(dictionary, 'discount', language)}</div> <div>-{discount} USD</div> </div>  
<div className='items-total'>           <div>{getField(dictionary, 'total', language)}</div> <div>{total} USD</div>                           </div>
</div>   
</Box>
</>
)

}

const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(OrderItems);
  