import React, { useState, useEffect } from 'react';

function Clock() {
        const [time, setTime] = useState("");
      
        useEffect(() => {
          const interval = setInterval(() => {
            const date = new Date();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);
          }, 1000);
          return () => clearInterval(interval);
        }, []);
      
        return (
          <div>
            {time}
          </div>
        );
      }

export default Clock;
