import React from 'react';
import "./greeting.css";
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";


function Greeting({ name, translation, language }) {
  const date = new Date();
  const hour = date.getHours();

//   console.log('trans',translation)

const dictionary = getDictionary(translation, 'home');
//   console.log('dic',dictionary)

  let greeting;

  if (hour >= 6 && hour < 12) {
    greeting = getField(dictionary, 'morning-greeting', language);
  } else if (hour >= 12 && hour < 18) {
    greeting = getField(dictionary, 'afternoon-greeting', language);
  } else {
    greeting = getField(dictionary, 'evening-greeting', language);
  }

  return  (
    <body className='greet-body'>
  
  <div className="greeting"> {greeting} <span className="name"> {name}! </span></div>

  
   </body>
  );
}


const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(Greeting);
 