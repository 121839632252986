import React from 'react';
import {Container} from '@mui/material';
import StartWork from './start-work/start-work';
import Greeting from './Greeting/greetings';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import RandomSentence from './random-sentence';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

import './home.css'
function Home({ translation, language, }) {


//   console.log('trans',translation)

//   const dictionary = getDictionary(translation, 'home');
//   console.log('dic',dictionary)
//  const title = getField(dictionary, 'greeting message', language);
// console.log('tit' , title)
  return (
    <Container className='home-container'>
      <div className='greeting-container'>
        <div className='greeting-ready'>
          <div className="greeting">
            <Greeting name="Johnny" />
          </div>
          <div className='ready'>
            <RandomSentence />
          </div>
        </div>

        <div className='greet-buttons'>
          <IconButton sx={{ color: 'white', backgroundColor: '#1DB4B8', '&:hover': { backgroundColor: '#1DB4B8' } }}>
            <PhoneInTalkRoundedIcon />
          </IconButton>
          <IconButton sx={{ color: 'white', backgroundColor: '#1DB4B8', '&:hover': { backgroundColor: '#1DB4B8' } }}>
            <MessageRoundedIcon />
          </IconButton>
        </div>
      </div>

      <div className="startwork">
        <StartWork />
   
      </div>
      
    </Container>
  );
}

const mapStateToProps = (state) => ({
 translation: state.translation.translation,
 language: state.translation.language,
});

const mapDispatchToProps = (dispatch) => ({
   
 });

 export default connect(mapStateToProps, mapDispatchToProps)(Home);

