import { PrivateApiCall, PublicApiCall } from "./interceptors";

const errorCatch = (error) => {
  console.log("ERROR API CALL", error, error?.response);
  if (error.response) {
    if (error.reponse?.data) {
      return error.response?.data;
    }
    return error.response;
  } else {
    return error;
  }
};


/** ******************************************************************************************* */
/** ************************************* LANGUAGE ******************************************** */
/** ******************************************************************************************* */
//get translation
async function getTranslation() {
  return await PublicApiCall.get(`/language/?platform=driver-app`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

export {
  // TRANSLATION
  getTranslation,
};
