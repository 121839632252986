import React  from 'react'
import '../ClosingReport/single-closing-report.css'
function SingleClosingReport({number, payment, delivery, price}) {

        return (
                <>
              
                <div className='single-cr-container'>

                <div className='single-cr-title-container'>
                <div className='single-cr-title'>Order</div>
                <div className='single-cr-tag-nbr'><div className='single-cr-tag'>#</div><div className='single-cr-nbr'>{number}</div></div>
                <div className='single-cr-payment'>{payment}</div>
                </div>
                
                <div className='single-cr-delivery-price'>
                <div className='single-cr-delivery-info'>
                <div>Delivery time</div> <div className='single-cr-delivery-time'>{delivery}</div>
                </div>
                <div className='single-cr-price'>{price} USD</div>
                </div>
                
                </div>
                </>
    


        );
    }
 
export default SingleClosingReport;