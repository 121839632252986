import React, { useState } from 'react';
import { ReactComponent as MoodAngry } from '../../../Assets/emoji-angry.svg';
import { ReactComponent as MoodAngryFilled } from '../../../Assets/emoji-angry-fill.svg';
import { ReactComponent as MoodFrown } from '../../../Assets/emoji-frown.svg';
import { ReactComponent as MoodFrownFilled } from '../../../Assets/emoji-frown-fill.svg';
import { ReactComponent as MoodNeutral } from '../../../Assets/emoji-neutral.svg';
import { ReactComponent as MoodNeutralFilled } from '../../../Assets/emoji-neutral-fill.svg';
import { ReactComponent as MoodSmile } from '../../../Assets/emoji-smile.svg';
import { ReactComponent as MoodSmileFilled } from '../../../Assets/emoji-smile-fill.svg';
import { ReactComponent as MoodHappy } from '../../../Assets/emoji-laughing.svg';
import { ReactComponent as MoodHappyFilled } from '../../../Assets/emoji-laughing-fill.svg';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";

import './moodRating.css';

function MoodRating({translation, language}) {
  const dictionary = getDictionary(translation, 'direction');

  const [hoveredIcon, setHoveredIcon] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');
  const [selectedMood, setSelectedMood] = useState('');

  const handleIconHover = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleIconLeave = () => {
    setHoveredIcon('');
  };

  const handleIconClick = (iconName, moodText) => {
    if (selectedIcon === iconName) {
      setSelectedIcon('');
      setSelectedMood('');
    } else {
      setSelectedIcon(iconName);
      setSelectedMood(moodText);
    }
  };
  
  return (
    <div className='mood-rating-container'>
    <div className="mood-ratings">
      <div
        onMouseEnter={() => handleIconHover('angry')}
        onMouseLeave={handleIconLeave}
        onClick={() => handleIconClick('angry', getField(dictionary, 'very-dissatisfied', language) )}
        className={`mood-icon ${
          selectedIcon === 'angry' ? 'selected' : ''
        }`}
      >
        {selectedIcon === 'angry' || hoveredIcon === 'angry' ? (
          <MoodAngryFilled className="very-dissatisfied mood-size" />
        ) : (
          <MoodAngry className="very-dissatisfied mood-size" />
        )}
      </div>

      <div
        onMouseEnter={() => handleIconHover('frown')}
        onMouseLeave={handleIconLeave}
        onClick={() => handleIconClick('frown', getField(dictionary, 'dissatisfied', language) )}
        className={`mood-icon ${
          selectedIcon === 'frown' ? 'selected' : ''
        }`}
      >
        {selectedIcon === 'frown' || hoveredIcon === 'frown' ? (
          <MoodFrownFilled className="dissatisfied mood-size" />
        ) : (
          <MoodFrown className="dissatisfied mood-size" />
        )}
      </div>

      <div
        onMouseEnter={() => handleIconHover('neutral')}
        onMouseLeave={handleIconLeave}
        onClick={() => handleIconClick('neutral', getField(dictionary, 'not-bad', language) )}
        className={`mood-icon ${
          selectedIcon === 'neutral' ? 'selected' : ''
        }`}
      >
        {selectedIcon === 'neutral' || hoveredIcon === 'neutral' ? (
         <MoodNeutralFilled className="neutral mood-size" /> 
        ) : (
          <MoodNeutral className="neutral mood-size" />
        )}
      </div>

      <div
        onMouseEnter={() => handleIconHover('smile')}
        onMouseLeave={handleIconLeave}
        onClick={() => handleIconClick('smile', getField(dictionary, 'satisfied', language) )}
        className={`mood-icon ${
          selectedIcon === 'smile' ? 'selected' : ''
        }`}
      >
        {selectedIcon === 'smile' || hoveredIcon === 'smile' ? (
          <MoodSmileFilled className="satisfied mood-size" />
        ) : (
          <MoodSmile className="satisfied mood-size" />
        )}
      </div>

      <div
        onMouseEnter={() => handleIconHover('happy')}
        onMouseLeave={handleIconLeave}
        onClick={() => handleIconClick('happy', getField(dictionary, 'very-satisfied', language) )}
        className={`mood-icon ${
          selectedIcon === 'happy' ? 'selected' : ''
        }`}
      >
        {selectedIcon === 'happy' || hoveredIcon === 'happy' ? (
          <MoodHappyFilled className="very-satisfied mood-size" />
        ) : (
          <MoodHappy className="very-satisfied mood-size" />
        )}
      </div>
    </div>

    {selectedIcon !== '' ? (
  <div className="selected-mood">{selectedMood}</div>
) : (
  <div className="selected-mood">{getField(dictionary, 'choose', language)}</div>
)}

      </div>
      
  );
}

const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(MoodRating);