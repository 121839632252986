import React from 'react'
import { Box} from '@mui/material'
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";

 function SummaryPayment({translation, language}) {
  const dictionary = getDictionary(translation, 'history');
return(
<>
<Box className= 'summary-payment-box' >
<div className='summary-payment-container' >
    <div className='summary-payment-title'>{getField(dictionary, 'summary-payment-title', language)}</div>
  <div className='summary-payment-number'> <div>{getField(dictionary, 'number-of-orders-title', language)}</div>  <div>15</div> </div> 
  <div className='summary-payment-cash'>  <div>{getField(dictionary, 'cash-amount', language)}</div>     <div>533 USD</div> </div>
  <div className='summary-payment-card'>   <div>{getField(dictionary, 'card-amount', language)}</div>     <div>428 USD</div> </div>
  <div className='summary-payment-online'>   <div>{getField(dictionary, 'online-amount', language)}</div>     <div>272 USD</div>  </div>

  <div className='summary-payment-total'> <div className='summary-payment-total-titles'> <b>{getField(dictionary, 'total-amount', language)}</b>  <i>{getField(dictionary, 'total-amount-sub-text', language)}</i> </div>  <p>1233 USD</p> </div>
  <div className='summary-payment-delivery'> <div>{getField(dictionary, 'delivery-charge', language)}</div>     <div>72 USD</div> </div>
  <div className='summary-payment-tip'>  <div>{getField(dictionary, 'tip-amount', language)}</div>     <div>92 USD</div> </div>

</div>
</Box>
</>
)
}

const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(SummaryPayment);