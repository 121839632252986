import React from 'react';
import { TextField, Button, Container, InputAdornment } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import '../login/login.css'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

export const Login = ({translation, language}) => {
  const dictionary = getDictionary(translation, 'login');

  const handleSubmit = (e) => {
    e.preventDefault();
    

  };


  return (
<body className='body-login'>
  <Container className='container-login' maxWidth="sm">
    <form className='login-form' onSubmit={handleSubmit}>
      
      <div className='login-head'>
        <div className='login-title'>{getField(dictionary, 'login-title', language)}</div>
        <div className='login-subtext'>{getField(dictionary, 'login-title-subtext', language)}</div>
      </div>

      <div className='login-inputs'>
          <TextField
            className='email-field'
            placeholder={getField(dictionary, 'username-input', language)}
            type="email"
            variant="filled"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon className='email-icon'/>
                </InputAdornment>  ),}} />

          <TextField
            placeholder={getField(dictionary, 'password-input', language)}
            type="Password"
            variant="filled"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon className='password-icon'/>
                </InputAdornment>  ),}} />

      </div>
      <div className='login-button'>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{backgroundColor:'white',
            color:'#1DB4B8',
            height:'3.5rem',
            fontSize:'20px',
            borderRadius:'15px',
            textTransform:'none',
            '&:hover': {backgroundColor: '#F2F2F2'}}}
          > {getField(dictionary, 'login-button', language)} </Button>
        </div>

        <div className='login-reset-create'>

        <Link to="/#" className='password-forgotten'>
        {getField(dictionary, 'forgot-password-button', language)}
          </Link>

          <Link to="/signup" className='go-signup-page'>
          {getField(dictionary, 'no-account-button', language)}
          </Link>

        </div>
          
      </form>
   
    </Container>
    </body>
  );
};


const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(Login);