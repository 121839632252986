import React from 'react';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
export default function SmallMap () {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });
if (!isLoaded) return <div>Loading...</div>;
return <Map />;
}

function Map(){
    const center =useMemo(()=>({lat:33.972875, lng: 35.610006}) ,[]);

    return (
    
    <GoogleMap 
    zoom={16} 
    center={center} 
    mapContainerClassName='small-map' 
    style={{height:"100%", margin:"0", padding:"0"}}> 
    <Marker position={center} />
    </GoogleMap>
    
    );

}