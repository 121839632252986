import React  from 'react'
import { Box } from '@mui/material';
import "./single-history-order.css"
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

 function SingleHistory({number, address, delivery, price, translation, language}) {
    const dictionary = getDictionary(translation, 'history');
    const handleClick = () => {
        window.location.href = "/orders/details";
        };
        return (
                <>
              
                <Box className='single-history-box' onClick={handleClick} >
                <div className='history-title-container'>
                <div className='history-title'>{getField(dictionary, 'order-title', language)}</div>
                <div className='history-tag-nbr'><div className='order-tag'>#</div><div className='order-nbr'>{number}</div></div>
                <div className='history-details'>{getField(dictionary, 'details-title', language)}</div>
                </div>
                
                    <div className='history-location'>{address}</div>

                <div className='history-delivery-price'>

                <div className='history-delivery-info'>

                <div>{getField(dictionary, 'delivery-time-title', language)}</div> <div className='history-delivery-time'>{delivery}</div>
                
                </div>

                <div className='history-price'>{price} USD</div>

                </div>
                </Box>
                </>
    


        );
    }
 
    const mapStateToProps = (state) => ({
        translation: state.translation.translation,
        language: state.translation.language,
       });
       
       const mapDispatchToProps = (dispatch) => ({
          
        });
       
        export default connect(mapStateToProps, mapDispatchToProps)(SingleHistory);