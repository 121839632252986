import React, { forwardRef } from 'react';
import "./direction.css"
import Dialog from '@mui/material/Dialog';
import MapDirection from "./map-direction/map-direction";
import Slide from '@mui/material/Slide';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import DeliveryInfo from "./delivery-info/delivery-info";
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref}  {...props}  />;
  });

 function Direction ({open, onClose, translation, language}) {
    const dictionary = getDictionary(translation, 'direction');
return(
    <>
 <Dialog className='direction-dialog'
        fullScreen
        open={open}
        TransitionComponent={Transition}>

  <div className="direction-head">
    <div to="/details" className='back-to-details' onClick={onClose}>
        <IconButton >
            <ArrowBack sx={{color:'black', fontSize:'2rem'}}/>
        </IconButton>
    </div>
     <div className='direction-title'>{getField(dictionary, 'direction-title', language)}</div>
  </div> 

    <MapDirection className='map-direction'/>
<div className="direction-delivery-info">
    <DeliveryInfo className="direction-delivery-info"/>
</div>
</Dialog>
 
    </>
)}

const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(Direction);