export const getDictionary = (translation, component) => {
    let dictionary = translation?.filter((row)=>{
       return row?.page === component;
    })
    return dictionary;
}


export const getField = (dictionary, field, language) => {
    if(dictionary?.length === 0 || !dictionary[0]?.values || !dictionary[0]?.values[field]) {
     return "No translation";
    }
   
    const value = dictionary[0]?.values[field][language] ? dictionary[0]?.values[field][language] : dictionary[0]?.values[field]["english"] ? dictionary[0]?.values[field]["english"] : "No translation";
   
    return value;
}
