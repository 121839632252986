import React, { forwardRef } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Zoom from '@mui/material/Zoom';
import './delivery-completed.css'
import MoodRating from '../Rating/moodRating';
import { ReactComponent as Coins } from '../../../Assets/Icon awesome-coins.svg'
import { ReactComponent as Confetti } from '../../../Assets/Group 8213.svg'
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";

const Transition = forwardRef(function Transition(props, ref) {
  return <Zoom direction="up" ref={ref} {...props} />;
});

const DeliveryCompleted = ({open, onClose, translation, language}) => {
  const dictionary = getDictionary(translation, 'direction');

  return (
    <div>
     
      <Dialog
       className='delivery-completed-dialog'
        fullScreen
        open={open}
        TransitionComponent={Transition}>
         
<div className="delivery-completed">

<div className="delivery-completed-top">
<div className="delivery-completed-title"><Confetti/>{getField(dictionary, 'delivery-completed', language)}</div>
<div className="for-driver">
<div className="text-for-driver">{getField(dictionary, 'text-you-got', language)}</div>
<div className="amount-for-driver">3.00 USD</div>
<div className="text-for-driver">{getField(dictionary, 'text-from-this-trip', language)}</div>
<div className="tips-for-driver"> <Coins style={{width:'25px', height:'25px'}}/> +1.00 USD {getField(dictionary, 'text-from', language)} Sam!</div>
</div>
</div>

<div className="delivery-completed-bottom">
<div className="rating">
<div className="rate-customer-title">{getField(dictionary, 'customer-rating', language)}</div>
<div className="rating-icons">
<MoodRating/>
</div>

</div>

<div className="rating-buttons">
<Button 
    type="submit" variant="contained" fullWidth
    sx={{backgroundColor:'white', color:'#1DB4B8',
    height:'3rem',width: '10rem', fontSize:'18px', fontWeight:'400',
    borderRadius:'20px',  textTransform:'none',
    '&:hover': {backgroundColor: '#F2F2F2'}}}
    > {getField(dictionary, 'submit-button', language)} </Button>
<Button onClick={onClose}
    variant="text" fullWidth
    sx={{ color:'#FFFFFF', fontSize:'18px', fontWeight:'400',
    height:'3rem',width: '10rem', textTransform:'none'}}
    > {getField(dictionary, 'skip-button', language)} </Button>
</div>
</div>

</div>


      </Dialog>
    </div>
  );
}

   const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCompleted);