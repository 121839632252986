import React from 'react'
import { BottomNavigation } from '@mui/material';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

  const BottomNav = () => {
    const location = useLocation();
    const activeUrl = location.pathname;

    const shouldShowBottomNav = !['/orders/details', '/direction','/login','/signup'].includes(
      location.pathname
    );
  
    return (
<>
      {shouldShowBottomNav && (

      <Box sx={{ flexGrow: 1, marginTop:'60px'}}>
      <BottomNavigation sx={{ position:'fixed', bottom: 0, width: '100%' }} value={activeUrl} showLabels >
        <BottomNavigationAction
          label="Home"
          value="home"
          icon={<HomeIcon />}
          component={Link}
          to="/"
          sx={{
            color: activeUrl === '/' ? '#1DB4B8' : '#757575',
            '&.Mui-selected': { color: '#1DB4B8' },
          }}
        />
        <BottomNavigationAction
          label="Orders"
          value="orders"
          icon={<FactCheckOutlinedIcon />}
          component={Link}
          to="/orders"
          sx={{
            color: activeUrl.startsWith('/orders') ? '#1DB4B8' : '#757575',
            '&.Mui-selected': { color: '#1DB4B8' },
          }}
        />
        <BottomNavigationAction
          label="History"
          value="history"
          icon={<HistoryOutlinedIcon />}
          component={Link}
          to="/history"
          sx={{
            color: activeUrl.startsWith('/history') ? '#1DB4B8' : '#757575',
            '&.Mui-selected': { color: '#1DB4B8' },
          }}
        
        />
        <BottomNavigationAction label="Profile" value="profile" icon={<PersonIcon />} 
        sx={{
          color: activeUrl === '/profile' ? '#1DB4B8' : '#757575',
            '&.Mui-selected': { color: '#1DB4B8' },
          }}/>
      </BottomNavigation>
      </Box>
      )}
</>
    );
  }

  export default BottomNav;