const ItemsData = [ 
    {quantity: 2,
    name: 'French Fries',
    price: 10,
},
{quantity: 1,
    name: 'Vegetable Spring Rolls',
    price: 12,
},

{quantity: 3,
    name: 'Mozzarella Sticks',
    price: 13,
},

{quantity: 1,
    name: 'Mozzarella Burger',
    price: 27,
},

{quantity: 1,
    name: 'Crispy Shrimps',
    price: 17,
},

{quantity: 1,
    name: 'Nachoss',
    price: 11,
},
    
    
    
    

    ];
    
    export default ItemsData;
    
    
    
    