import axios from "axios";
import { CLOUD9_API } from "../constants";
import {store} from '../../index';

export const PrivateApiCall = axios.create({
  baseURL: CLOUD9_API,
});

PrivateApiCall.interceptors.request.use(
  (req) => {
    const state = store.getState();
    const token = state.auth.token;
    req.headers.Authorization = `Bearer ${token}`;
    req.headers["incoming-origin"] = `delivery`;
    return req;
  },
  (err) => {
    throw err;
  }
);

PrivateApiCall.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    throw err;
  }
);


export const PublicApiCall = axios.create({
  baseURL: CLOUD9_API,
});

PublicApiCall.interceptors.request.use(
  (req) => {
    req.headers["incoming-origin"] = `delivery`;
    return req;
  },
  (err) => {
    throw err;
  }
);

PublicApiCall.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    throw err;
  }
);
