import React, { Component } from 'react'
class SplitLine extends Component {
 
    render() { 
        return (
<div className='split-line'>
    
</div>
        );
    }
}
 
export default SplitLine;