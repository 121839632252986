import React , {useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import OrderDetails from './pages/details/details';
import Orderspage from './pages/orders/orderspage';
import BottomNav from './components/AppBars/bottom-nav';
import History from './pages/history/history';
import ClosingReport from './pages/history/ClosingReport/closing-report';
import Login from './pages/login/login';
import ButtonAppBar from './components/AppBars/appbar';
import Signup from './pages/signup/signup';
import Direction from './pages/direction/direction';

import { connect } from 'react-redux';
import { actions } from './redux/actions/TranslationsActions';

import './App.css'
const App=({fetchTranslation})=> {

  useEffect(() => {
    fetchTranslation()
  }, [])
  
  return (
    <>
      <Router>
        <ButtonAppBar />
         <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/orders" element={<Orderspage />} />
         <Route path="/orders/details" element={<OrderDetails />} />
         <Route path="/orders/details/direction" element={<Direction />} />
         <Route path="/history" element={<History />} />
         <Route path="/history/closing-report" element={<ClosingReport />} />
         <Route path="/login" element={<Login />} />
         <Route path="/signup" element={<Signup />} />
        </Routes>
       <BottomNav />
      </Router>
    </>
  );
}

const mapStateToProps = (state) => ({
 
});

const mapDispatchToProps = (dispatch) => ({
    fetchTranslation: () => dispatch(actions.getTranslation()),
 });


 export default connect(mapStateToProps, mapDispatchToProps)(App);
