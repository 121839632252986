import React, { useState, forwardRef } from 'react';
import './delivery-summary.css'
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Todaysdate from '../../../components/date-time/Todays-date';
import Clock from '../../../components/date-time/clock';
import Zoom from '@mui/material/Zoom';
import DeliveryCompleted from '../delivery-completed/delivery-completed';
import './delivery-summary.css'
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom direction="up" ref={ref} {...props} />;
  });
  

 const DeliverySummary = ({translation, language}) => {
  const dictionary = getDictionary(translation, 'direction');

    const [open, setOpen] = useState(false);
    const [popup, setPopUp] = useState(false);
    const [arrived, setArrived] = useState(false);

    const handleClickOpen = () => {
      handlePopIn();
      handleArrived();
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleArrived = () => {
      setArrived(true);
    };
 
    const handlePopIn = () => {
      setTimeout(() => {
      setPopUp(true);
    }, 5000);
  };
  
    const handlePopOut = () => {
      setPopUp(false);
    };
    return(
<>

      <div className="delivered-button"> 

<Button onClick={handleClickOpen} disabled={arrived} sx={{ backgroundColor: '#1DB4B8', color:'white', width:'15rem',height:'3.5rem',fontWeight:'500',fontFamily:'Segoe UI',borderRadius:'18px',
fontSize:'1.2rem',  marginBottom:'1rem', textTransform:'none', '&:hover': {backgroundColor: '#1DB4B8',} }}>
{arrived ? getField(dictionary, 'arrived-button', language) : getField(dictionary, 'delivered-button', language) }</Button> 
</div>

<Dialog
open={open} TransitionComponent={Transition}
keepMounted onClose={handleClose}
aria-describedby="alert-dialog-slide-description"
>

<div className='delivery-summary-container'>

<div className="summary-title">{getField(dictionary, 'summary-title', language)}</div>

<div className='delivery-order-detail'>
<div className='delivery-order-title'>{getField(dictionary, 'order-title', language)}</div>
<div className='delivery-order-fullnbr'>
<div className='delivery-order-tag'>#</div>
<div className='delivery-order-nbr'>1001</div>
</div>
</div>

<div className="delivery-summary-details">
<div className="summary-icon-text"><PersonRoundedIcon sx={{fontSize:'1.1rem'}}/>Vini Jr.</div>
<div className="summary-icon-text"><CalendarMonthIcon sx={{fontSize:'1.1rem'}}/> <Todaysdate/></div>
<div className="summary-icon-text"><AccessTimeRoundedIcon sx={{fontSize:'1.1rem'}}/> <Clock/></div>
<div className="summary-icon-text summary-location"><LocationOnRoundedIcon sx={{fontSize:'1.3rem'}}/> Zone A- Street abc, Builg. xyz, Floor 3, 307.</div>
</div>

<div className="summary-food-title">{getField(dictionary, 'food-collected', language)}</div>

<div className='delivery-payment-status'>
<div className='delivery-payment-status-title'>{getField(dictionary, 'payment-status-title', language)}</div>
<div className='summary-payment-method'>CARD/<div style={{color:'#04A147'}}>{getField(dictionary, 'payment-status-paid', language)}</div>
</div>
</div>

<div className='summary-total-container'>
<div className='summary-total total-title'>{getField(dictionary, 'total', language)}</div>
<div className='summary-total total-amount'>84.00 USD</div>
</div>

</div>

</Dialog>   


<div className='delivery-completed-popup' > <DeliveryCompleted open={popup} onClose={handlePopOut}/> </div> 
</>
    );}

    const mapStateToProps = (state) => ({
      translation: state.translation.translation,
      language: state.translation.language,
     });
     
     const mapDispatchToProps = (dispatch) => ({
        
      });
     
      export default connect(mapStateToProps, mapDispatchToProps)(DeliverySummary);