import React from 'react';
import './single-item.css';
function SingleItem ({name, quantity, price}) {


return(
<>
<div className='item-container'>

<div className='item-quantity-name'>
<div className='item-quantity'>x{quantity}</div>
<div className='item-name'>{name}</div>
</div>

<div className='item-price'>{price} USD</div>
</div>


</>
)
    
}


export default SingleItem;