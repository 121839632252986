import React from 'react';
import {IconButton} from '@mui/material';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import Avatar from '@mui/material/Avatar';
import viniImage from '../../../Assets/vini-dance.jpg'
import DeliverySummary from '../delivery-summary/delivery-summary';
import './delivery-info.css'
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";

    function DeliveryInfo({translation, language}) {
        const dictionary = getDictionary(translation, 'direction');
return(
<>
<div className="delivery-info">

<div className="delivery-details">
<div className='delivery-address-container'>
<div className='address-icon'><NearMeRoundedIcon sx={{fontSize:'1.2rem'}}/></div> 
<div className="delivery-address-text">
<div className="client-address">EDL factory, Zouk Mikael,</div>
<div className="delivery-address-title">{getField(dictionary, 'delivery-address', language)}</div>
</div>
</div>
<div className='delivery-time-container'>
<div className='time-icon'><AccessTimeRoundedIcon sx={{fontSize:'1.2rem'}}/></div>
<div className="delivery-time-text">
<div className="time-duration">25 minutes</div>
<div className="time-title">{getField(dictionary, 'delivery-time', language)}</div>
</div>
</div>
</div>

<div className='client-info-container'>
<Avatar className='client-icon' alt="Vini" src={viniImage} sx={{width:'50px', height:'50px'}}/>
<div className="client-text">
<div className="client-name">Vini Jr.</div>
<div className="client-title">{getField(dictionary, 'customer', language)}</div>
</div>
<div className='contact-client' >
<IconButton sx={ {color:'white', width:'55px', height:'55px', backgroundColor: '#96D9DB', '&:hover': {backgroundColor: '#96D9DB',} }}>
<CallRoundedIcon sx={{fontSize:'30px'}}/></IconButton> 
<IconButton sx={ {color:'white', width:'55px', height:'55px', backgroundColor: '#96D9DB', '&:hover': {backgroundColor: '#96D9DB',} }}>
<ChatRoundedIcon sx={{fontSize:'30px'}}/></IconButton> 
</div>
</div>

<div className='delivery-summary-popup' > <DeliverySummary /> </div> 

</div>
</>
);}

const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInfo);