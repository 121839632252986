import React from 'react'
import SingleOrder from './single-order/single-order'
import './orders.css';
import OrderData from './order-data';
import SwitchButton from './switch-button'
import { Button, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

  
function Orderspage({translation, language }) {
    const dataComp = OrderData.map((data)=> {
        return(
         <SingleOrder 
            number={data.number}
            distance={data.distance}
            address={data.address}
            status={data.status}
            pickup={data.pickup}
            delivery={data.delivery}
        />  
)})

const dictionary = getDictionary(translation, 'orders');
        return (    
            
    <Container  maxWidth="sm">
      
        <div className='orders-container'>
            <Grid><div className="orders-fixed">
              <div className="switch-button">  <SwitchButton/> </div>  
        </div></Grid>
            
        <div className='orders'></div>
         {dataComp} 
        </div>

            <Link to="/" className='start-all-button' >
<Button sx={{
        backgroundColor: '#1DB4B8', 
        color:'white',
        width:'350px',
        height:'60px',
        fontWeight:'bold',
        fontFamily:'Segoe UI',
        borderRadius:'20px',
        fontSize:'1.5rem',
        textTransform:'none',
        '&:hover': {backgroundColor: '#1DB4B8',} }}>{getField(dictionary, 'start-all-button', language)}</Button> </Link> 
    </Container>
);}
 
const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(Orderspage);