import React, { useState, useEffect } from 'react';

const sentences = [
  "Ready to start your journey?",
  "Ready to hit the road?",
  "Are you set to embark on your route?",
  "Is it time to start your adventure?",
  "Is it time to begin your quest?",
  "Ready to start your mission?",
  
];

function RandomSentence() {
  const [sentence, setSentence] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * sentences.length);
    const randomSentence = sentences[randomIndex];
    setSentence(randomSentence);
  }, []);

  return (
    
      <p>{sentence}</p>
    
  );
}

export default RandomSentence;