import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import './start-work.css';
import Clock from '../../../components/date-time/clock';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import Todaysdate from '../../../components/date-time/Todays-date';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../utils/translation";



const StartWork = ({translation, language }) => {
  const [isWorking, setIsWorking] = useState(false);
  const [buttonColor, setButtonColor] = useState('#1DB4B8');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [duration, setDuration] = useState(null);

//   console.log('trans',translation)

const dictionary = getDictionary(translation, 'home');
//   console.log('dic',dictionary)


  useEffect(() => {
    if (isWorking) {
      const interval = setInterval(() => {
        setDuration(calculateDuration(startTime, new Date()));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isWorking, startTime]);

  const handleButtonClick = () => {
    if (!isWorking) {
      const startTime = new Date();
      setIsWorking(true);
      setButtonColor('#CF0000');
      setStartTime(startTime);
    } else {
      const endTime = new Date();
      const duration = calculateDuration(startTime, endTime);
      setIsWorking(false);
      setButtonColor('#1DB4B8');
      setEndTime(endTime);
      setDuration(duration);
    }
  };

  const calculateDuration = (start, end) => {
    const diff = Math.abs(end - start);
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const UserStatus = ({ status }) => {
    let userstatus, icon;
    if (status === 'on') {
      userstatus = getField(dictionary, 'user-status-online', language);
      icon = <CheckCircleOutlineIcon />;
    } else if (status === 'off') {
      userstatus = getField(dictionary, 'user-status-offine', language);
      icon = <HighlightOffIcon />;
    } else userstatus = 'Unknown';

    return (
      <div className="user-status">
        <span className={`user-status-icon user-status-icon--${status}`}>{icon}</span>
        <span className={`user-status-text user-status-text--${status}`}>{userstatus}</span>
      </div>
    );
  };

  const buttonText = isWorking ? getField(dictionary, 'stop-button', language) : getField(dictionary, 'start-button', language);

  return (
    <>
      <Box className="start-work">
        <div className="sw-container">
          <div className="date-time">
            <div className="date-container">
              <CalendarMonthIcon className="date-icon" />
              <Todaysdate className="date-home" />
            </div>
            <div className="time-container">
              <AccessTimeIcon className="clock-icon" />
              <Clock className="clock-home" />
            </div>
          </div>

          <div className="user-status-home">
            <UserStatus status={isWorking ? 'on' : 'off'} />
          </div>

          <Button
            className="button-start"
            variant="contained"
            sx={{
              backgroundColor: buttonColor,
              fontFamily: 'Segoe UI',
              borderRadius: '20px',
              fontSize: '17px',
              textTransform: 'none',
              '&:hover': { backgroundColor: buttonColor },
            }}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </div>
      </Box>

      <Container>
        {startTime && (
          <div className="work-timing">
            <div className="start-time">
              Started at: {startTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}
            </div>
            {endTime && (
              <div className="end-time">
                Stopped at: {endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}
              </div>
            )}
            {endTime && <div className="duration">Duration: {duration}</div>}
          </div>
        )}
      </Container>
    </>
  );
};


const mapStateToProps = (state) => ({
 translation: state.translation.translation,
 language: state.translation.language,
});

const mapDispatchToProps = (dispatch) => ({
   
 });

 export default connect(mapStateToProps, mapDispatchToProps)(StartWork);
