import React from 'react'
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import viniImage from '../../../../Assets/vini-dance.jpg'
import './client.css'
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../../utils/translation";

 function Client ({translation, language}) {

    const dictionary = getDictionary(translation, 'details');
return (           
<>      
<div className='customer-title'>{getField(dictionary, 'customer-title', language)}</div>          
<div className='customer-details'>
<div className='customer-avatar'>
<Avatar alt="Vini" src={viniImage}/> <div className="customer-name">Vini Jr.</div>
</div>
<div className='customer-call-message' >
<IconButton sx={ {color:'white', backgroundColor: '#1DB4B8', '&:hover': {backgroundColor: '#1DB4B8',} }}>
<PhoneInTalkRoundedIcon /></IconButton> 
<IconButton sx={ {color:'white', backgroundColor: '#1DB4B8', '&:hover': {backgroundColor: '#1DB4B8',} }}>
<ChatRoundedIcon /></IconButton> 
</div>
</div>          
</>
);}
const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(Client);
  