import React, { useState } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";


 function SwitchButton({translation, language }) {

  const [isSelected, setIsSelected] = useState('active');
  const handleToggle = (buttonType) => {
      if (isSelected !== buttonType) {
        setIsSelected(buttonType);
      }};

      const dictionary = getDictionary(translation, 'orders');

  return (
  
  <div className='switch-container'
       style={{
            width: '220px', height: '45px',
            backgroundColor: '#FFFFFF', display: 'flex',
            margin: '0px',  borderRadius: '15px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            justifyContent: 'center', alignItems: 'center',
             }}>
    <Button onClick={() => handleToggle('active')}
            className={`switch-active ${isSelected === 'active' ? 'active' : ''}`}
            style={{
              backgroundColor: isSelected === 'active' ? '#1DB4B8' : '#FFFFFF',
              width: '110px', height: '45px', margin: '0px',
              borderRadius: '15px', textTransform: 'none',
              color: isSelected === 'active' ? '#FFFFFF' : '#000000',
              fontWeight:'normal',  fontSize:'15px'
                  }}>
            {getField(dictionary, 'acive-button', language)}
    </Button>

    <Button onClick={() => handleToggle('on-the-way')}
            className={`switch-on-the-way ${isSelected === 'on-the-way' ? 'active' : ''}`}
            style={{
                 backgroundColor: isSelected === 'on-the-way' ? '#1DB4B8' : '#FFFFFF',
                 width: '110px', height: '45px', margin: '0px',
                 borderRadius: '15px', textTransform: 'none',
                 color: isSelected === 'on-the-way' ? '#FFFFFF' : '#000000',
                 fontWeight:'normal', fontSize:'15px'
                 }}>
            {getField(dictionary, 'on-the-way-button', language)}
    </Button>

  </div>
);}

const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
 });
 
 const mapDispatchToProps = (dispatch) => ({
    
  });
 
  export default connect(mapStateToProps, mapDispatchToProps)(SwitchButton);
 