import React, { Component } from 'react'
import '../details/details.css'
import Clock from '../../components/date-time/clock';
import Address from './components/address/address';
import Location from './components/location/location';
import SplitLine from './components/SplitLine';
import Client from './components/client/client';
import OrderItems from './components/items/items';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import DateNow from '../../components/date-time/date-now';
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../utils/translation";

function Details ({translation, language}) {

    const dictionary = getDictionary(translation, 'details');


        return (

            <>

    <div className='order-details-head'>
            
                <Link to="/orders" className='back-to-orders'>  <IconButton > <ArrowBack sx={{color:'black', fontSize:'2rem'}}/> </IconButton> </Link>
                <div className='details-order-detail'>
                    <div className='order-title'>{getField(dictionary, 'order-title', language)}</div>
                    <div className='order-fullnbr'>
                        <div className='order-tag'>#</div>
                        <div className='order-nbr'>1001</div>
                    </div>
                </div>
               

                <div className='other-details-info'>
                <div className='order-date'> <div className='details-order-title'>{getField(dictionary, 'date-title', language)} </div> <DateNow /> </div>
                <div className='order-time'> <div className='details-order-title'>{getField(dictionary, 'time-title', language)}</div> <Clock /> </div>
                <div className='order-branch'> <div className='details-order-title'>{getField(dictionary, 'branch-title', language)} </div> <i style={{ color: '#818181' }}> Jounieh Branch </i> </div>
                </div>     
    </div>

                <div className='details-container'>
                <div className='client-order-details-container'>  
                    <SplitLine className="split-1"/>
                    <Address className="client-address" />
                   <Location className="client-location"/>
                    <SplitLine className="split-2"/>
                    <Client />
                    <SplitLine className="split-3"/>
                    <div className='customer-payment-status'>
                        <div className='customer-payment-status-title'>{getField(dictionary, 'payment-status-title', language)}</div>
                        <div style={{ display: 'flex', fontSize: '0.9rem' }}>
                        CARD/<div style={{ color: 'red' }}>{getField(dictionary, 'payment-status-unpaid', language)}</div>
                        </div>
                        </div>
                    </div>
               <OrderItems/>
<Button sx={{
        backgroundColor: '#1DB4B8', 
        marginTop:'2rem',
        marginBottom:'2rem',
        color:'white',
        width:'20rem',
        height:'3.4rem',
        fontWeight:'bold',
        fontFamily:'Segoe UI',
        borderRadius:'18px',
        fontSize:'1.3rem',
        textTransform:'none',
        '&:hover': {backgroundColor: '#1DB4B8',} }}>{getField(dictionary, 'start-button', language)}</Button>
                </div>

            </>
        );
    }


const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(Details);