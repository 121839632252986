import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './address.css'
import { connect } from 'react-redux';
import { getDictionary, getField } from "../../../../utils/translation";

  
function Address({translation, language}) {
    const dictionary = getDictionary(translation, 'details');

    return  (
      
    <div className='adress-box' >
    <h2 className='adress-title'> {getField(dictionary, 'address-title', language)} </h2>
    
        <div className='adress-location-distance'>
 <LocationOnIcon className='adress-location-logo' style={{color:'white', backgroundColor:'#1DB4B8', borderRadius:'50%', padding:'0.3rem'}}/> 
<div className='adress-distance'>2 km</div>
        
        <div style={{color:'red', fontSize:'0.9rem'}}>ZONE A</div>
    </div>

<div className='adress-street' > <div style={{fontWeight:'bold'}}>{getField(dictionary, 'street', language)}</div><div className='subtext'>Street abc</div></div>  
<div className='adress-building'> <div style={{fontWeight:'bold'}}>{getField(dictionary, 'building', language)}</div><div className='subtext'>Blg-xyz</div></div>
<div className='adress-floor' > <div style={{fontWeight:'bold'}}>{getField(dictionary, 'floor', language)}</div><div className='subtext'>3</div></div> 
<div className='adress-apartment'> <div style={{fontWeight:'bold'}}>{getField(dictionary, 'apartment', language)}</div><div className='subtext'>307</div></div> 
</div>
    
    );
  }
  
  const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
   });
   
   const mapDispatchToProps = (dispatch) => ({
      
    });
   
    export default connect(mapStateToProps, mapDispatchToProps)(Address);