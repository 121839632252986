import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import InfoIcon from '@mui/icons-material/Info';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Badge } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useLocation } from 'react-router-dom';
import DateNow from '../date-time/date-now';

export default function App() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Home';
      case '/orders':
        return "Today's Jobs" ;
      case '/history':
        return 'History';
      default:
        return '';
    }
  };

  const shouldShowAppBar = !['/orders/details', '/history/closing-report','/direction','/login','/signup'].includes(
    location.pathname
  );

  return (
    <div>
      {shouldShowAppBar && (
        <Box sx={{ flexGrow: 1, marginBottom: '60px' }}>
          <AppBar position="fixed" sx={{ backgroundColor: '#F9F9F9', color: 'black', boxShadow:'none' }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer('left', true)}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h4"
                component="div"
                sx={{ flexGrow: 1,  textTransform: 'none', }}
              >
                <div style={{textAlign: 'center',  fontSize: '1.5rem', fontWeight:'bold'}}> {getTitle()}  </div>

                <div style={{textAlign: 'center',  fontSize: '1rem', color:'#818181'}}>
                  {location.pathname === '/orders' && <DateNow/>} 
                  </div>
              </Typography>
              
              <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={17} color="error">
                  <NotificationsOutlinedIcon />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>
      )}

      <SwipeableDrawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer('left', false)} onKeyDown={toggleDrawer('left', false)}>
          <List>
            {['Settings', 'Log In', ].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton >
                  <ListItemIcon>
                    {index % 2 === 0 ? <SettingsIcon /> : <AccountCircleIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['Support', 'About'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <SupportAgentRoundedIcon /> : <InfoIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
